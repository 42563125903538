body {
    background-color: #191919;
	/* font-family: 'Open Sans'; */
    margin-top: 10%;
    margin-bottom: 50px;
}

#question {
    background-color: #191919;
    color: white;
    padding: 10px;
    text-align: center;
}

#question h4{
    background-color: #fff;
    color: #0b0e21;
    display:inline-block;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 600;
}

#question p{
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 2px;
    padding-left: 50px;
    padding-right: 50px;
}

#answers ul{
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#answers li {
    background-color: #191919;
    border: 2px solid grey;
    min-height: 70px;
    width: 48%;
    display: flex;
    margin-bottom: 30px;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    cursor: pointer;
}

/* #answers li span {
    background-color: #191919;
    color: #fff;
    font-size: 30px;
    flex: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .3s ease;
    border-right: 2px solid grey;
} */

#answers li p {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    flex: calc(100% - 75px);
    margin: auto 20px;
    transition: color .3s ease;
}

#answers li:after{
    display: block;
}

#answers li:hover {
    transform: scale(1.03);
}

#answers li.right {
    border-color: #1ea55b;
    color: #1ea55b;
}

#answers li.right span {
    background-color: #1ea55b;
}

#answers li.right p {
    color: #1ea55b;
}

#answers li.wrong {
    border-color: #dc0a0a;
    color: #dc0a0a;
}

#answers li.wrong span {
    background-color: #dc0a0a;
}

#answers li.wrong p {
    color: #dc0a0a;
    font-weight: 600;
}

#submit {
    text-align: center;
    margin: 20px 0;
}

.fancy-btn {
    border: 2px solid darkgray;
    border-radius: 0;
    background-color: #0b0e21;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 75px;
    margin: 0 auto;
    text-transform: uppercase;
    transition: color .2s ease, background-color .2s ease;
}

.selectQuiz{
    margin:0 auto;
    width:50%;
}

.fancy-btn:hover {
    background-color: #fff;
    color: #0b0e21;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.popup h1 {
    background-color: #191919;
    color: white;
    border-bottom: 1px solid #ccc;
    padding: 20px;
    margin-top: 0;
}

.popup p {
    font-size: 18px;
    letter-spacing: 1px;
    margin: 20px 10% 0;
}

.popup .fancy-btn{
    margin: 20px auto;
}

footer {
    background-color: #0b0e21;
    color: white;
    letter-spacing: 1px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    text-align: center;
}

footer p {
    margin: 0;
}

footer small {
    margin-bottom: 10px;
}

footer span {
    color: crimson;
}

@media (max-width: 768px) {
    #question p {
        padding-left: 10px;
        padding-right: 10px;
    }
    
    #answers li {
        width: 100%;
    }
    
    #submit button {
        width: 100%;
    }
}
@media (max-width: 480px) {
    
    footer {
        padding: 5px;
    }
    
    footer small {
        display: none;
    }

    .selectQuiz{
        margin:0 auto;
        width:90%;
    }
}